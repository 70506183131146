import React, { useMemo } from 'react';
import objectPath from 'object-path';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { actions as kycReminderAction } from '../../../app/modules/Dashboard/_redux/dashboardReducers';

import { useHtmlClassService } from '../_core/MetronicLayout';
import { Header } from './header/Header';
import { HeaderMobile } from './header-mobile/HeaderMobile';
import { Aside } from './aside/Aside';
import { Footer } from './footer/Footer';
import { LayoutInit } from './LayoutInit';
import QuickUser from './extras/offcanvas/QuickUser';
import { ScrollTop } from './extras/ScrollTop';
import { BottomMenuList } from './aside/aside-menu/BottomMenu';
import FixedKycReminder from '../../../app/modules/Common/KycReminder/KycReminder';
import { DownloadAppBanner } from '../../../app/modules/Common/DownloadAppBanner';
import { kycStatuses } from '../../../app/helpers/constants';

export function Layout({ children }) {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const { pathname } = location;
  const profile = useSelector(state => state.profile);
  const kycStatus = profile?.investor?.kycStatus;

  const dispatch = useDispatch();

  const recentTimeDisabledAppDownloadBar =
    profile?.user?.recentTimeDisabledAppDownloadBar;

  const { loading } = profile;
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      selfLayout: objectPath.get(uiService.config, 'self.layout'),
      asideDisplay: objectPath.get(uiService.config, 'aside.self.display'),
      subheaderDisplay: objectPath.get(uiService.config, 'subheader.display'),
      desktopHeaderDisplay: objectPath.get(
        uiService.config,
        'header.self.fixed.desktop',
      ),
      contentCssClasses: uiService.getClasses('content', true),
      contentContainerClasses: uiService.getClasses('content_container', true),
      contentExtended: objectPath.get(uiService.config, 'content.extended'),
    };
  }, [uiService]);

  const urls = ['kyc-information', 'investor-info'];

  const removeKycReminder = () => {
    if (kycStatus === kycStatuses.REJECTED && location?.pathname !== '/home') {
      return true;
    }

    const included = urls.some(url => location?.pathname?.includes(url));
    return included;
  };

  const handleKycReminderClick = () => {
    dispatch(kycReminderAction.displayKycRejectionReasonModal(true));
  };

  return layoutProps.selfLayout !== 'blank' ? (
    <>
      {/*begin::Main*/}
      <HeaderMobile />
      <div className="d-flex flex-column flex-root">
        {/*begin::Page*/}
        <div className="d-flex flex-row flex-column-fluid page">
          {layoutProps.asideDisplay && <Aside />}
          {/*begin::Wrapper*/}

          <div
            className="d-flex flex-column flex-row-fluid wrapper content-container"
            id="kt_wrapper"
          >
            <Header />
            {/*begin::Content*/}
            <div
              id={`kt_content`}
              className={`content ${pathname.replace('/', '')}-page${
                layoutProps.contentCssClasses
              } d-flex flex-column flex-column-fluid`}
            >
              {/* {layoutProps.subheaderDisplay && <SubHeader />} */}
              {/*begin::Entry*/}
              {!layoutProps.contentExtended && (
                <div className="d-flex flex-column-fluid">
                  {/*begin::Container*/}
                  <div className={layoutProps.contentContainerClasses}>
                    {!removeKycReminder() ? (
                      <FixedKycReminder
                        handleOnclick={handleKycReminderClick}
                      />
                    ) : null}

                    {!recentTimeDisabledAppDownloadBar && !loading ? (
                      <DownloadAppBanner />
                    ) : null}
                    {children}
                  </div>
                  {/*end::Container*/}
                </div>
              )}

              {layoutProps.contentExtended && { children }}
              {/*end::Entry*/}
            </div>
            {/*end::Content*/}
            <Footer />
            <BottomMenuList />
            {/* <div class="navbar"> */}
            {/* </div> */}
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Page*/}
      </div>
      <QuickUser />
      <ScrollTop />

      {/* <StickyToolbar /> */}
      {/*end::Main*/}
      <LayoutInit />
    </>
  ) : (
    // BLANK LAYOUT
    <div className="d-flex flex-column flex-root">{children}</div>
  );
}
