import * as auth from '../_redux/authRedux';

import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import Loader from '../../Common/Loader';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { requestEmailVerification } from '../_redux/authCrud';
import { ConfirmButton } from '../../Common/ReusableButtons';

const SignupEmailVerification = props => {
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [responseMessage, setResponseMessage] = useState(null);
  const searchParams = new URLSearchParams(props.location.search);
  const verificationCode = searchParams.get('code');
  const id = searchParams.get('id');

  const verifyEmail = async () => {
    if (!id || !verificationCode) {
      setResponseMessage('Verification details are missing');
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const response = await requestEmailVerification(id, verificationCode);
      setLoading(false);
      if (response.status === 200) {
        setResponseMessage(response.data.message);
        setSuccess(true);
      }
    } catch (error) {
      setResponseMessage(
        error?.response?.data
          ? error.response.data.message
          : 'Verification not successful',
      );
      setLoading(false);
      setSuccess(false);
    }
  };

  useEffect(() => {
    verifyEmail();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {success ? (
            <div>
              <div className="text-center">
                <h3 className="font-size-h3">Successfully Verified</h3>

                <p>Kindly sign in to your account</p>

                <h6 className="mt-5" style={{ color: '#67CCCA' }}>
                  {responseMessage}
                </h6>
              </div>

              <Link to="/auth/login">
                <ConfirmButton
                  buttonText="Sign In"
                  classes={
                    'mx-auto justify-content-center align-items-center d-flex mt-8'
                  }
                />
              </Link>
            </div>
          ) : (
            <div className="text-center">
              <h3 className="font-size-h3">Verification Error</h3>

              <p>Kindly confirm your verification code</p>

              <h6 className="mt-10" style={{ color: '#F05B7F' }}>
                {responseMessage}
              </h6>

              <div className="text-center">
                <Link to="/auth/resendVerification">
                  <ConfirmButton
                    buttonText="Resend Verification Email"
                    loading={loading}
                    classes={'my-8'}
                  />
                </Link>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default injectIntl(connect(null, auth.actions)(SignupEmailVerification));
